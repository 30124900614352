.report-feedback {
  background: #25a6f7;
  color: #ffffff;
  margin: auto;
  display: block;
  margin-top: 250px;
  /* padding: 10px 15px; */
}

.report-feedback:hover {
  background: #317df7;
  color: #ffffff;
}

div.container-fluid div.inner-pages {
  width: 200%;
}
.spacer5 {
  height: 37px;
}
.sidebar {
  overflow-y: scroll;
}
.app-header .navbar-brand {
  width: 280px !important;
}
@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    margin-left: -145.5px !important;
  }
}
.nestable-item {
  margin-left: -11px !important;
}
